/**
 * Appends a size and a file type strings to a thumbnail path to complete the url.
 *   Available thumbnail sizes:
 *
 *     - Widescreen: 128x72, 256x144, 384x216, 640x360, 768x432, 1024x576, 1408x792, 1792x1008, 1920x1080
 *     - Standard: 25x25, 50x50, 75x75, 150x150, 300x300, 450x450, 600x600
 *     - Tags Only: 25x45, 50x86, 75x129, 100x172, 140x241, 180x310
 *
 *   Entities thumbnail size types:
 *     - Course: Widescreen
 *     - VideoGuide: Widescreen
 *     - Tag: Standard and Tags Only
 *     - User: Standard
 *     - Player: Standard
 *     - Team: Standard

 * @param path
 * @param size
 * @param fileType - 'jpeg' or 'png', defaults to 'jpeg'
 */
import { Observable, of } from 'rxjs';
import { concatMap, withLatestFrom } from 'rxjs/operators';
import { PlaylistItem } from '../../models/playlist-item.model';
import { AppSettings } from './AppSettings';

export function getThumbnailUrl(
    path: string | null | undefined,
    size: string,
    fileType: string = 'jpeg'
) {
    if (!path) {
        return '';
    }
    return `${path}${size}.${fileType}`;
}

/**
 * Gets entity id from permalink
 *
 * @export
 * @param {string} permalink
 * @returns {string}
 */
export function getEntityIdFromPermalink(permalink: string): string {
    if (permalink) {
        const permalinkChunks = permalink.split('~');
        return permalinkChunks[permalinkChunks.length - 1];
    }

    return '';
}

export function getFullDiscordUsername(username: string, discriminator: string): string {
    return username + '#' + discriminator;
}

export function getDiscordThumbnailUrl(discordUserId: string, avatarId: string): string {
    return `${AppSettings.DISCORD_CDN_BASE_URL}/avatars/${discordUserId}/${avatarId}.png`;
}

export function getCourseStarsArray(courseRating: number): string[] {
    const ratingIcons: string[] = [];
    const cr = courseRating / 2;
    const starsCount = 5;
    const halfStarValue = 0.5;

    for (let i = 0; i < starsCount; i++) {
        if (cr > i) {
            if (i + halfStarValue === cr) {
                ratingIcons.push('star_half');
            } else {
                ratingIcons.push('star');
            }
        } else {
            ratingIcons.push('star_outline');
        }
    }

    return ratingIcons;
}

/**
 * Filters out all values from an array for which the comparator function does not return true.
 * Can be used to find the differentiating elements between 2 arrays.
 * Example: differenceWith([1, 2, 3], [1, 2], (a, b) => a === b); // [3]
 */
export const differenceWith = (array1: any[], array2: any[], comparator: Function) => {
    return array1.filter(a => array2.findIndex(b => comparator(a, b)) === -1);
};

export function mockArray(length: number) {
    return new Array(length);
}

export function withLatestCached<TSource, TSecond>(
    second: (input: TSource) => Observable<TSecond>
) {
    return function latestFromisBad(source: Observable<TSource>) {
        return new Observable(subscriber => {
            const subscription = source
                .pipe(concatMap(action => of(action).pipe(withLatestFrom(second(action)))))
                .subscribe(
                    value => {
                        try {
                            subscriber.next(value);
                        } catch (err) {
                            subscriber.error(err);
                        }
                    },
                    err => subscriber.error(err),
                    () => subscriber.complete()
                );
            return subscription;
        }) as Observable<[TSource, TSecond]>;
    };
}

export function createPlaylist(videoGuides: any): PlaylistItem[] {
    if (!Array.isArray(videoGuides)) {
        videoGuides = [videoGuides];
    }

    return videoGuides.map(
        (vg, ind): PlaylistItem => {
            return {
                id: vg._id,
                sources: vg.sources,
                title: vg.title,
                index: ind,
                poster: getThumbnailUrl(vg.thumbnailPath, '1280x720'),
                thumbnailPath: vg.thumbnailPath,
                videoId: vg.videoId,
                duration: vg.duration,
                isFree: vg.isFree || vg.giveawayMetadata?.isRedeemed,
                isRedeemed: vg.giveawayMetadata?.isRedeemed,
                permalink: vg.permalink,
                coursePermalink: vg.coursePermalink,
                game: vg.game,
                timelineThumbnailsPaths: vg.timelineThumbnailsPaths,
                tagName: vg.tag?.name
            };
        }
    );
}
export function getCardBrandImage(brand: string) {
    return `/assets/images/card-brands/full-set/${brand.toLowerCase()}.svg`;
}

export function slugify(text: string): string {
    return text
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-');
}

